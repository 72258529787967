<template>
  <form @submit.prevent="handleSubmit()">
    <div class="">
      <div class="form-group">
        <label for="">Select Insured Type</label
        ><select
          @change="disableDate(data.insuredType)"
          v-model="data.insuredType"
          class="form-control"
          id="insurancetype"
          required
        >
          <option value="">Select Insured Type</option>
          <option value="Individual">Individual</option>
          <option value="Corporate">Corporate</option>
        </select>
      </div>

      <div class="row" v-if="data.insuredType == 'Individual'">
        <div class="form-group col-md-2">
          <label for="">Select Title</label
          ><select
            v-model="data.title"
            class="form-control"
            id="title"
            required
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr</option>
            <option value="Mrs.">Mrs</option>
            <option value="Miss.">Miss</option>
            <option value="Dr.">Dr</option>
            <option value="Egnr.">Egnr</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group col-md-5">
          <label for="">First Name</label
          ><input
            class="form-control"
            id="firstname"
            type="text"
            v-model="data.firstName"
            placeholder="Enter First Name"
            required
          />
        </div>
        <div class="form-group col-md-5">
          <label for="">Last Name</label
          ><input
            class="form-control"
            id="lastname"
            type="text"
            v-model="data.lastName"
            placeholder="Enter Last Name"
            required
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">Select Gender</label
          ><select
            v-model="data.gender"
            class="form-control"
            id="title"
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div class="form-group col-md-5">
          <label for="">Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.phoneNo"
            placeholder="Enter Phone Number"
          />
        </div>

        <div class="form-group col-md-5">
          <label for="">Email Address</label
          ><input
            class="form-control"
            id="email"
            type="email"
            v-model="data.email"
            placeholder="Enter Email"
            required
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">State Of Residence</label
          ><select
            @change.prevent="getLgas(state.id)"
            v-model="state"
            class="form-control"
            id="title"
            required
          >
            <option :value="{}" v-if="data.stateOfResidence != ''">
              {{ data.stateOfResidence }}
            </option>
            <option v-else :value="{}">Select State</option>

            <option v-if="states.length == 0">Loading...</option>
            <option
              v-for="state in states"
              v-else
              :key="state.id"
              :value="state"
            >
              {{ state.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label for="">Select LGA</label
          ><select required v-model="data.lga" class="form-control" id="title">
            <option :value="data.lga" v-if="data.lga != ''">
              {{ data.lga }}
            </option>
            <option value="">Select LGA</option>
            <option v-if="lga.length == 0">Loading...</option>
            <option v-for="lga in lgas" v-else :key="lga.id" :value="lga.name">
              {{ lga.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-8">
          <label for="">Address</label
          ><input
            class="form-control"
            id="address"
            type="text"
            v-model="data.address"
            placeholder="Enter Address"
            required
          />
        </div>
        <div class="form-group col-md-4">
          <label for="">Date Of Birth</label
          ><input
            class="form-control"
            id="dateofbirth"
            ref="dateofbirth"
            type="date"
            v-model="data.dateOfBirth"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Means Of Identification</label
          ><select
            v-model="data.meansOfId"
            class="form-control"
            id="meansofid"
            required
          >
            <option value="">Select Means Of Identification</option>
            <option value="Passport">Passport</option>
            <option value="NIN">NIN</option>
            <option value="Drivers License">Drivers License</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Identification Number</label
          ><input
            class="form-control"
            id="idnumber"
            type="text"
            v-model="data.idNumber"
            placeholder="Enter ID Number"
            required
          />
        </div>

        <div class="form-group col-md-6">
          <label for="">BVN</label
          ><input
            class="form-control"
            id="bvn"
            type="text"
            v-model="data.bvn"
            placeholder="Enter BVN"
            
          />
        </div>

        <div class="form-group col-md-6">
          <label for="">TIN</label
          ><input
            class="form-control"
            id="tin"
            type="text"
            v-model="data.tin"
            placeholder="Enter TIN"
            required
          />
        </div>

        <div class="form-group col-md-6">
          <label for="">Occupation</label>
          <v-select
            v-model="occupation"
            class="form-select"
            label="name"
            :options="paginated"
            :filterable="false"
            @search="onSearch"
          >
            <template #search="{ attributes, events }">
              <span class="mt-1 mx-3 py-1" v-if="occupation === null">
                {{ data.occupation }}
              </span>
              <input
                class="vs__search"
                :required="
                  data.occupation !== 'Search Occupation' ? false : true
                "
                v-bind="attributes"
                v-on="events"
              />

              <!-- <li class="pagination">
                <button
                  class="btn"
                  type="button"
                  :disabled="!hasPrevPage"
                  @click="offset -= limit"
                >
                  Prev
                </button>
                <button
                  class="btn"
                  type="button"
                  :disabled="!hasNextPage"
                  @click="offset += limit"
                >
                  Next
                </button>
              </li> -->
            </template>
          </v-select>
        </div>

        <div class="form-group col-md-6">
          <label for="">Marketing Staff</label>
          <select
            @change="addMarketer(marketer)"
            v-model="marketer"
            class="form-control"
            required
          >
            <option
              v-if="data.marketersName !== ''"
              :value="{ ...data.marketersName, ...data.marketersID }"
            >
              {{ data.marketersName }}
            </option>
            <option v-else value="">Select Marketing Staff</option>
            <option
              v-for="staff in brokerMarketers"
              :value="staff"
              :key="staff.id"
            >
              {{ staff.mktStaff }}
            </option>
          </select>
        </div>
      </div>

      <div class="row" v-if="data.insuredType == 'Corporate'">
        <div class="form-group col-md-6">
          <label for="">Company Name</label
          ><input
            class="form-control"
            id="companyName"
            type="text"
            v-model="data.firstName"
            required
            placeholder="Enter Company Name"
          />
        </div>

        <div class="form-group col-md-6">
          <label for="">Company Email Address</label
          ><input
            class="form-control"
            id="email"
            type="email"
            v-model="data.email"
            placeholder="Enter Email"
            required
          />
        </div>

        <div class="form-group col-md-2">
          <label for="">Company State Of Residence</label
          ><select
            @change.prevent="getLgas(state.id)"
            v-model="state"
            class="form-control"
            id="title"
            required
          >
            <option :value="{}" v-if="data.stateOfResidence != ''">
              {{ data.stateOfResidence }}
            </option>
            <option v-else :value="{}">Select State</option>
            <option v-if="states.length == 0">Loading...</option>
            <option
              v-for="state in states"
              v-else
              :key="state.id"
              :value="state"
            >
              {{ state.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label for="">Select Company LGA</label
          ><select required v-model="data.lga" class="form-control" id="title">
            <option value="">Select LGA</option>
            <option :value="data.lga" v-if="data.lga != ''">
              {{ data.lga }}
            </option>
            <option v-if="lga.length == 0">Loading...</option>
            <option v-for="lga in lgas" v-else :key="lga.id" :value="lga.name">
              {{ lga.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-8">
          <label for="">Company Address</label
          ><input
            class="form-control"
            id="address"
            type="text"
            v-model="data.address"
            placeholder="Enter Address"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company's Date Of Incorporation</label
          ><input
            class="form-control"
            id="dateofincorporation"
            type="date"
            v-model="data.dateOfBirth"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.phoneNo"
            placeholder="Enter Phone Number"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Landline</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.landLine"
            placeholder="Enter Landline Number"
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company Partner Name</label
          ><input
            class="form-control"
            id="companyPartnerName"
            type="text"
            v-model="data.companyPartnerName"
            placeholder="Company Partner Name"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company RC Number</label
          ><input
            class="form-control"
            id="rcNumber"
            type="text"
            v-model="data.rcNumber"
            placeholder="Enter RC Number"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Company TIN</label
          ><input
            class="form-control"
            id="tin"
            type="text"
            v-model="data.tin"
            placeholder="Enter TIN"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Select Director's Title</label
          ><select
            v-model="data.directorTitle"
            class="form-control"
            id="title"
            required
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr</option>
            <option value="Mrs.">Mrs</option>
            <option value="Miss.">Miss</option>
            <option value="Dr.">Dr</option>
            <option value="Egnr.">Egnr</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's First Name</label
          ><input
            class="form-control"
            id="firstname"
            type="text"
            v-model="data.directorFirstname"
            placeholder="Enter First Name"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Last Name</label
          ><input
            class="form-control"
            id="lastname"
            type="text"
            v-model="data.directorSurname"
            placeholder="Enter Last Name"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Other Name</label
          ><input
            class="form-control"
            id="othername"
            type="text"
            v-model="data.directorOthername"
            placeholder="Enter Other Name"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Gender</label
          ><select
            v-model="data.gender"
            class="form-control"
            id="title"
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Phone Number</label
          ><input
            class="form-control"
            id="phonenumber"
            type="text"
            v-model="data.directorPhoneNumber"
            placeholder="Enter Phone Number"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Email Address</label
          ><input
            class="form-control"
            id="email"
            type="email"
            v-model="data.directorEmail"
            placeholder="Enter Email"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Date Of Birth</label
          ><input
            class="form-control"
            id="dateofbirth"
            type="date"
            v-model="data.directorDateOfBirth"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Means Of Identification</label
          ><select
            v-model="data.directorMeansOfId"
            class="form-control"
            id="meansofid"
            required
          >
            <option value="">Director's Means Of Identification</option>
            <option value="Passport">Passport</option>
            <option value="NIN">NIN</option>
            <option value="Drivers License">Drivers License</option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Director's Identification Number</label
          ><input
            class="form-control"
            id="idnumber"
            type="text"
            v-model="data.directorIdNumber"
            placeholder="Enter ID Number"
            required
          />
        </div>

        <div class="form-group col-md-4">
          <label for="">Occupation</label>
          <v-select
            v-model="occupation"
            class=""
            label="name"
            :options="paginated"
            :filterable="false"
            @search="onSearch"
          >
            <template #search="{ attributes, events }">
              <span class="mt-1 mx-3 py-1" v-if="occupation === null">
                {{ data.occupation }}
              </span>
              <input
                class="vs__search"
                :required="
                  data.occupation !== 'Search Occupation' ? false : true
                "
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>

        <div class="form-group col-md-4">
          <label for="">Marketing Staff</label>
          <select
            @change="addMarketer(marketer)"
            v-model="marketer"
            class="form-control"
            required
          >
            <option
              v-if="data.marketersName !== ''"
              :value="{ ...data.marketersName, ...data.marketersID }"
            >
              {{ data.marketersName }}
            </option>
            <option v-else value="">Select Marketing Staff</option>
            <option
              v-for="staff in brokerMarketers"
              :value="staff"
              :key="staff.id"
            >
              {{ staff.mktStaff }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <button type="button" class="disabled btn btn-primary my-3">Back</button>

    <button type="submit" class="btn btn-primary float-right my-3">
      Save / Next
    </button>
  </form>
</template>

<style>
.disabled:hover {
  cursor: not-allowed;
}
</style>

<script>
//import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "@/stores/marine";
import { useMainStore } from "@/stores/main";

export default {
  name: "Buy Marine",
  //components: { AppLayout },
  data() {
    return {
      search: "",
      offset: 0,
      limit: 100,
      state: {},
      test: ["foo", "bar", "baz"],
      lga: {},
      occupation: null,
      marketer: "",
      currency: {},
      data: {
        title: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        gender: "",
        email: "",
        address: "",
        stateOfResidence: "",
        lga: "",
        submittedBy: "Marine Portal",
        insuredType: "",
        dateOfBirth: "",
        occupation: "Search Occupation",
        occupationId: "",
        meansOfId: "",
        idNumber: "",
        bvn: "",
        directorTitle: "",
        directorSurname: "",
        directorFirstname: "",
        directorOthername: "",
        directorPhoneNumber: "",
        directorEmail: "",
        directorDateOfBirth: "",
        directorMeansOfId: "",
        directorIdNumber: "",
        rcNumber: "",
        companyPartnerName: "",
        landLine: "",
        tin: "",
        marketersName: "",
        marketersID: "",
      },
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "loading",
      "states",
      "componentLoading",
      "lgas",
      "brokerMarketers",
      "occupations",
      "marineData",
    ]),
    ...mapState(useMainStore, ["user"]),
    filtered() {
      return this.occupations.filter((occupation) =>
        occupation.name
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
  },

  watch: {
    occupation: function () {
      if (this.occupation !== null) {
        this.addOccupation(this.occupation);
      }
    },
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getStates",
      "getLga",
      "getBrokerMarketers",
      "getOccupations",
      "incrementStep",
      "saveStep",
    ]),

    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },

    addMarketer(marketer) {
      this.data.marketersName = marketer.mktStaff;
      this.data.marketersID = marketer.mktStaffID.toString();
    },

    addOccupation(occupation) {
      if (this.occupation !== {}) {
        this.data.occupation = occupation.name;
        this.data.occupationId = occupation.id.toString();
      }
    },

    getLgas(id) {
      this.data.stateOfResidence = this.state.name;
      this.getLga(id);
    },

    handleSubmit() {
      this.saveStep(this.data);
      this.incrementStep(2);
      this.$router.push(`/broker/buymarine/step-2`);
    },

    disableDate(insuredType) {
      setTimeout(function () {
        var dtMin = new Date();
        dtMin.setFullYear(dtMin.getFullYear() - 18);

        var minMonth = dtMin.getMonth() + 1;
        var minDay = dtMin.getDate();
        var minYear = dtMin.getFullYear();
        if (minMonth < 10) minMonth = "0" + minMonth.toString();
        if (minDay < 10) minDay = "0" + minDay.toString();

        let maxDate = minYear + "-" + minMonth + "-" + minDay;
        const dob = document.getElementById("dateofbirth");
        dob.setAttribute("max", maxDate);

        if (insuredType == "Corporate") {
          var dtToday = new Date();

          var month = dtToday.getMonth() + 1;
          var day = dtToday.getDate();
          var year = dtToday.getFullYear();
          if (month < 10) month = "0" + month.toString();
          if (day < 10) day = "0" + day.toString();

          let todayDate = year + "-" + month + "-" + day;

          const doi = document.getElementById("dateofincorporation");
          doi.setAttribute("max", todayDate);
        }
      }, 1000);
    },
  },

  created() {
    this.data = { ...this.data, ...this.marineData };
    this.marketer = {
      ...this.data.marketersName,
      ...this.data.marketersID,
    };
    if (this.data.email == "") this.data.email = this.user.email;
    if (this.data.phoneNo == "") this.data.phoneNo = this.user.phoneNo;
    this.getStates();
    this.getBrokerMarketers();

    if (this.data.insuredType) {
      this.data.dateOfBirth = this.data.dateOfBirth.split("T")[0];
      this.disableDate(this.data.insuredType);
    }

    this.getOccupations();
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>